jQuery(document).ready(function($){


    jQuery(".scrollup").click(function() {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    $(window).on("scroll load", function() {
        if ($(this).scrollTop() > 98){
            $(".scrollup").show();
        }
        else{
            $(".scrollup").hide();
        }
    });



});
